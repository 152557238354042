import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState
} from "react";
import { Data, Events, Overrides } from "@transformd-ltd/sdk";
import { Helmet } from "react-helmet";
import {
  Formatic,
  CustomCheckboxList,
  CustomRadioList,
  RelationshipCustom
} from "../components";
import type { Verification } from "../types/Verification";

const formProps = {
  apiServerUrl: "https://api-staging.transformd.com",
  serverUrl: "https://api-staging.transformd.com/graphql",
  subscriptionServerUrl: "wss://api-staging.transformd.com/subscriptions"
};

export type EmitterData = {
  fieldID: string;
  newValue: string | string[];
  oldValue: string | string[];
};

export type FormData = {
  [key: string]: any;
  getEmitter: () => any;
};

const Heading = ({ page }) => (
  <div className="space-y-8 text-center">
    <Helmet title="APLYiD Onboarding - Real Estate Demo" defer={false} />
    {page === "6270a35f27809c00124ce1a3" && (
      <div className="h-10">
        <h2 className="font-serif-finance text-finance-green-300 text-xl leading-tight lg:text-3xl lg:leading-10">
          Let's get started
        </h2>
      </div>
    )}
    {page === "6278680464f3d400735474e2" && (
      <div className="h-10">
        <h2 className="font-serif-finance text-finance-green-300 text-xl leading-tight lg:text-3xl lg:leading-10">
          Let's get your data
        </h2>
      </div>
    )}
    {page === "627881f264f3d4006351689b" && (
      <div className="flex flex-col items-center justify-center space-y-4">
        <h1 className="text-3xl">Listing address</h1>
        <p className="font-light text-xl">
          Let us know a few details about you so we can be as helpful as
          possible.
        </p>
      </div>
    )}
    {/* {page} */}
  </div>
);

const nonFields = ["heading", "richTextArea"];

const RealEstatePage: FunctionComponent = (): ReactElement => {
  const [page, setPage] = useState<string>("624ce8c22b1d8500091c18c7");
  const [name, setName] = useState<string>("");
  const [verificationResult, setVerificationResult] = useState<
    Verification | {}
  >({});
  const [hasValuables, setHasValuables] = useState<string>("NO");
  const [status, setStatus] = useState<string>("base");
  const [data] = useState<FormData>(new Data());

  const order = ({
    dataForm,
    submission,
    currentPage,
    visibleFields,
    event
  }) => {
    const { fields, sections, pages } = dataForm;

    const pagesMap = pages
      ?.map(paged =>
        paged?.sections?.map(sectionLol => {
          const newFields = sections
            ?.filter(section => section.id === sectionLol.id)
            ?.map(section => {
              const newSection = section.fields.map(fieldLol => {
                const newFields = fields
                  ?.filter(field => field.id === fieldLol.id)
                  ?.filter(field => !nonFields.includes(field.renderer))
                  ?.map(field => ({
                    fieldId: field.id,
                    value: submission?.fieldsById?.[field?.id]?.value ?? null,
                    errors: visibleFields?.[field?.id]?.errors
                      ? Object.keys(visibleFields?.[field?.id]?.errors)
                      : []
                  }));

                return newFields;
              });
              return newSection;
            })
            ?.flat(2);

          return {
            page: paged?.attributes?.label,
            id: paged?.id,
            currentPage: paged?.id === page,
            fields: newFields
          };
        })
      )
      ?.flat()
      ?.filter(paged => paged?.fields?.length)
      ?.filter(paged => paged?.currentPage)
      ?.shift();

    const hasValues = pagesMap?.fields?.some(field => field?.value);
    const hasErrors = pagesMap?.fields?.some(field => field?.errors?.length);

    // console.log({ pagesMap, hasValues, hasErrors });

    if (!hasValues && !hasErrors) {
      return "base";
    }

    if (hasValues && !hasErrors) {
      if (event === "valueChanged") {
        return "validating";
      }
      return "success";
    }

    if (hasErrors) {
      return "error";
    }

    return "base";
  };

  const getValues = (newData, event) => {
    if (
      newData?.formatic?.dataForm?.pages?.length &&
      newData?.formatic?.data?.submission &&
      newData?.formatic?.visibleFields
    ) {
      const newStatus = order({
        dataForm: newData.formatic.dataForm,
        submission: newData.formatic.data.submission,
        visibleFields: newData.formatic.visibleFields,
        currentPage: page,
        event
      });
      return setStatus(newStatus);
    }
    return setStatus("base");
  };

  const getVerificationData = renderData => {
    const verification: Verification | { type: null } =
      renderData?.formatic?.data?.submission?.fieldsById?.[
        "627853341fd4a100512c7334"
      ]?.value?.verification ?? {};

    // console.log(verification);

    if (verification && verification?.type) {
      setVerificationResult(verification);
    }
  };

  const inputChangeListener = () => {
    const emitter = data?.getEmitter();

    // Listen to page render events and set id of current page
    emitter.on(Events.PageRender, page => {
      const renderData = data?.store?.getState();
      getVerificationData(renderData);
      setPage(page.id);
      getValues(renderData, "pageRender");
    });

    // Listen to field change events and update state of some values (name,valuables)
    emitter.on(Events.ValueChanged, (emitterData: EmitterData) => {
      const valueChangedData = data?.store?.getState();
      //   console.log({
      //     fieldId: emitterData.fieldID,
      //     value: emitterData.newValue,
      //     valueChangedData
      //   });
      if (emitterData.fieldID === "624ce9f92b1d8500091c18d3") {
        setName(emitterData?.newValue?.toString());
      }
      if (emitterData.fieldID === "624e12d62b1d85001f01a916") {
        setHasValuables(emitterData?.newValue?.toString());
      }
      getValues(valueChangedData, "valueChanged");
    });

    emitter.on(Events.FieldValidated, () => {
      const validationData = data?.store?.getState();
      getValues(validationData, "fieldValidated");
    });

    emitter.on(Events.FieldError, () => {
      const errorData = data?.store?.getState();
      getValues(errorData, "fieldError");
    });
  };

  useEffect(() => {
    // console.log(page);
    inputChangeListener();
  }, [page]);

  return (
    <div
      className="page__example-fsf flex min-h-screen justify-center p-5 md:p-10"
      id="main"
    >
      <div className="w-full max-w-md space-y-4">
        <div className="flex justify-center">
          <img
            className="h-20"
            src="/img/logos/aa-money-logo.svg"
            alt="AA Money logo"
          />
        </div>
        <div className="rounded-md bg-[#F2F2F2] px-5 pb-5 pt-1.5">
          <Formatic
            {...formProps}
            data={data}
            formId={570}
            apiKey="17ac7e00498F46A4D858A1DAC087972f47Ae9d73554Ff065f1626eaA610D67Cd"
            environment="Staging"
            channel="master"
            initialValues={{}}
            config="default"
            theme="transformd"
          >
            <Overrides.OverrideFieldContainer
              component={CustomCheckboxList}
              type="checkboxList"
            />
            <Overrides.OverrideFieldContainer
              component={CustomRadioList}
              type="radioList"
            />
          </Formatic>
        </div>
      </div>
    </div>
  );
};

export default RealEstatePage;
